<template>
    <div class="main-div" :class="{ 'btn btn-borderless' : props.MatrixSize.size === 'extra small' }"> <!-- Adding this class so the div is clickable without any content inside -->
        <!-- Orig, Prev and Curr - Matrix extra small -->
        <div
            ref="cellPosition"
            v-if="props.MatrixSize.size === 'extra small'"
            class="btn btn-borderless btn-color fw-bold boxCell"
            style="max-width:25px; max-width:25px;"
            @click="checkEditEnabled"
            :disabled="!props.EditEnabled"
        >   
           <!-- Original -->
            <span
                v-if="(props.MatrixCValue === props.DetailAppAssessments[0]?.OrigCValue && props.MatrixFValue === props.DetailAppAssessments[0]?.OrigFValue)"
                :title="$t('Original (') + props.DetailAppAssessments[0]?.OrigCreatedBy + ', ' + $formatDate(props.DetailAppAssessments[0]?.OrigDate, 'Short Date') + ')'"
                style="cursor:help; font-size:0.75rem;"
            >{{ $t("O") }}</span>

             <!-- Previous -->
            <span
                v-if="(props.MatrixCValue === props.DetailAppAssessments[0]?.PrevCValue && props.MatrixFValue === props.DetailAppAssessments[0]?.PrevFValue)"
                :title="$t('Previous (') + props.DetailAppAssessments[0]?.PrevCreatedBy + ', ' + $formatDate(props.DetailAppAssessments[0]?.PrevDate, 'Short Date') + ')'"
                style="cursor:help; font-size:0.75rem;"
            >{{ $t("P") }}</span>

            <!-- Current -->
            <span
                class="text-decoration-underline"
                v-if="(props.MatrixCValue === props.DetailAppAssessments[0]?.Value && props.MatrixFValue === props.DetailAppAssessments[0]?.FrequencyValue)"
                :title="$t('Current (') + props.DetailAppAssessments[0]?.CreatedBy + ', ' + $formatDate(props.DetailAppAssessments[1]?.current?.AssessmentDate, 'Short Date') + ')'"
                style="cursor:help; font-size:0.75rem;"
            >{{ $t("C") }}</span>
        </div>

        <!-- Counted risks - Matrix small -->
        <a
            v-if="props.MatrixSize.size === 'small' && risks_loaded && countedRiskIDs > 0"
            class="counted_risks d-flex justify-content-center align-items-center"
            href="#"
            ref="popoverTrigger"
            @click="togglePopover"
            data-bs-toggle="popover"
            :data-bs-content="popoverContent"
        >
            {{ countedRiskIDs }}
        </a>

        <!-- Risk id's - Matrix medium and large -->
        <div  
            class="risk_ids" :style="'max-height:' + maxHeight"
            v-if="(props.MatrixSize.size === 'medium' || props.MatrixSize.size === 'large') && risks_loaded && style_added && risks.length > 0" 
        >
            <a class="d-flex justify-content-center align-items-center fw-bold p-1" :style="'font-size:' + fontSize" v-for="(row, rowIndex) in risks" :key="rowIndex" :href="'/ramslog/ramslogitem?ID=' + row.Risk_ID">
                <!-- <span v-if="row.IsCurrentAssessmentBeforeMeasures" style="margin-right:1px;" :style="'font-size:' + fontSizeMeasureText">(BM)</span> --> 
                <!-- <span v-if="row.IsCurrentAssessmentAfterMeasures" style="margin-right:1px;" :style="'font-size:' + fontSizeMeasureText">(AM)</span> --> 
                
                <i :class="[row.IsCurrentAssessmentBeforeMeasures ? 'bi bi-arrow-left-circle' : 'bi bi-arrow-right-circle']" style="margin-right:2px;"></i>
                <span class="d-flex justify-content-center align-items-center" >
                    {{ row.Risk_ID }}
                    <i class="bi bi-arrow-up" v-if="(row.Criticality && row.PreviousCriticality) && row.Criticality > row.PreviousCriticality"></i>
                    <i class="bi bi-arrow-down" v-if="(row.Criticality && row.PreviousCriticality) && row.Criticality < row.PreviousCriticality"></i>
                    <i class="bi bi-dash" v-if="(row.Criticality && row.PreviousCriticality) && row.Criticality === row.PreviousCriticality"></i>
                </span>
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, reactive, onMounted, onUnmounted, inject, watch } from 'vue';
    import { getOrCreateDataObject, deleteDataObject, DataItemModel } from 'o365-dataobject';
    import { MatrixSize, RiskID, RiskItem, EmittedValues } from 'rams.libraries.vue.components.matrix.MatrixInterfaces.ts';
    import { $t } from 'o365-utils';

    const random_number = Math.floor(Math.random() * (1000000 - 10 + 1)) + 10; // Creates a random number which will be used on the datasource names (this is purely because the ds's cant be named the same) 
    const countedRiskIDs = ref(0);
    
    let risks: RiskItem[] = reactive([]);
    const risks_loaded = ref(false);

    let maxHeight = ref("67px;"); 
    let maxWidth = ref("67px;");
    let fontSize = ref("11px;");
    let style_added = ref(false);
    
    const popoverTrigger = ref(null);
    let popover = ref(null);
    let popoverContent = ref("");
    const { updateToggleEditModal } = inject('toggleEditModal', { updateToggleEditModal: (value: boolean) => {}})
    
    const emits = defineEmits(['toggleSpinner', 'dataCell']);
    
    const props = defineProps({
        "MatrixSize": { 
            type: Object,
            default: {
                size: 'medium',
                class: 'table-matrix-md'
            } 
        }, 
        "RiskIDs": {
            type: Array, 
            default: () => []
        },
        "MatrixFValue": Number,
        "MatrixCValue": Number,
        "MaxCellValue": Number,
        "ConsequenceArea_ID": Number,
        "DetailAppAssessments": { // Contains current row of Consequence-assessment and Probability-assessment from detail app
            type: Array,
            default: () => []
        },
        "EditEnabled": {
            type: Boolean,
            default: false
        },
        "ButtonColor":{
            type: String,
            default: "black"
        }
    });

    const local_dsCurrentAssesments = getOrCreateDataObject({
        id: `dsCurrentAssesments_${random_number}`,
        viewName: 'aviw_RAMS_CurrentAssessments', maxRecords: -1,
        loadRecents: false,
        disableLayouts: true,
        fields: [
            { name: "Risk_ID", type: "int"},
            { name: "Title", type: "string"},
            { name: "CurrentFValue", type: "int"},
            { name: "CurrentCValue", type: "int"},
            { name: "Criticality", type: "int"},
            { name: "PreviousCriticality", type: "int"},
            { name: "ConsequenceType_ID", type: "int"},
            { name: "IsCurrentAssessmentBeforeMeasures", type: "boolean"},
            { name: "IsCurrentAssessmentAfterMeasures", type: "boolean"}
        ]
    });
    
    onMounted(() => {
        createCellData(props.MatrixSize, props.ConsequenceArea_ID, props.MatrixCValue, props.MatrixFValue, props.RiskIDs);
    });

    onUnmounted(() => {
        deleteDataObject(local_dsCurrentAssesments.id, local_dsCurrentAssesments.appId);
    });

    watch(props, (newVal) => {
        setCellStyle(newVal.MatrixSize);
    })

    const createCellData = async (pMatrixSize: MatrixSize, pConsequenceArea_ID, pCValue, pPValue, pRiskIDs: Array<RiskID>) => {  
        if(props.MatrixSize.size === 'extra small') { return; } // Celldata is managed direcly in the HTML for XS-matrix' (used in risk detail app)
        if(props.RiskIDs.length === 0) { return; } // Shouldn't create celldata if there's no risk-ids associated with the cell 

        if(props.MatrixFValue === 1 && props.MatrixCValue === 1) { // Start loading of spinner at first cell
            emits('toggleSpinner', true);
        }

        const whereClause = "CurrentCValue = " + pCValue + " AND CurrentFValue = " + pPValue + " AND Risk_ID IN (" + pRiskIDs + ") AND ConsequenceType_ID = " + pConsequenceArea_ID;
        local_dsCurrentAssesments.recordSource.whereClause = whereClause; 
        await local_dsCurrentAssesments.load().then((data: Array<DataItemModel>) => {
            data.forEach((risk: RiskItem) => {
                risks.push({
                    Risk_ID:                            risk.item.Risk_ID,
                    Title:                              risk.item.Title,
                    Criticality:                        risk.item.Criticality,
                    PreviousCriticality:                risk.item.PreviousCriticality,
                    IsCurrentAssessmentBeforeMeasures:  risk.item.IsCurrentAssessmentBeforeMeasures, 
                    IsCurrentAssessmentAfterMeasures:   risk.item.IsCurrentAssessmentAfterMeasures
                });
                
                const measureIcon = risk.item.IsCurrentAssessmentBeforeMeasures ? 'bi bi-arrow-left-circle me-1' : 'bi bi-arrow-right-circle me-1'
                const measureIconEl = `<i class="${measureIcon}"></i>`
                popoverContent.value += `<a class="d-inline-flex mb-1 mt-1" href="/nt/ramslog/ramslogitem?ID=${risk.item.Risk_ID}" target="_blank">${measureIconEl}${risk.item.Risk_ID + '-' + risk.item.Title}</a><br>`
            });

            countedRiskIDs.value = risks.length;
            
            risks_loaded.value = true;
        });
        
        setCellStyle(pMatrixSize);

        // Stop spinner when last cell is loaded/populated
        if(
            (props.MaxCellValue === 5 && props.MatrixFValue === 5 && props.MatrixCValue === 5) // Matrix is 5x5
            ||
            (props.MaxCellValue === 6 && props.MatrixFValue === 6 && props.MatrixCValue === 6) // Matrix is 6x6
        ) {
            emits('toggleSpinner', false);
        }
    }

    const setCellStyle = (matrixSize: MatrixSize) => {
        if(matrixSize.size === 'medium') { // Medium matrix: risk id's displayed w/ fitting style and popover
            maxHeight.value = "67px;"
            maxWidth.value = "67px;"
            fontSize.value = "11px;"
        } else if(matrixSize.size === 'large') { // Large matrix: risk id's displayed w/ fitting style and popover
            maxHeight.value = "97px;"
            maxWidth.value = "97px;"
            fontSize.value = "13px;"
        }

        style_added.value = true;
    }

    const togglePopover = () => {
        if(popover.value === null) { // Initializing popover when its first clicked, popovers initial value is null
            popover = new bootstrap.Popover(popoverTrigger.value, {
                content: popoverContent.value,
                html: true
            });

            popover.toggle();
        }
    }

    const cellPosition = ref(null);
    let emittedValues: EmittedValues = {
        Clicked : true,
        MatrixCValue: props.MatrixCValue,
        MatrixFValue: props.MatrixFValue,
        CellLeftPosition: 0,
        CellTopPosition: 0,
        Cell : {}
    }
    
    const checkEditEnabled = () => {
        if(!props.EditEnabled) {
            updateToggleEditModal(true); // Toggling edit modal in risk detail app
        }else{
            emittedValues.CellLeftPosition = cellPosition.value?.offsetParent.offsetLeft;
            emittedValues.CellTopPosition = cellPosition.value?.offsetParent.offsetTop;
            emittedValues.Cell =  cellPosition.value;
            emits('dataCell' , emittedValues);
        }
    }
</script>

<style scoped>
    .main-div {
        opacity: 0;
        animation: fadeInAnimation 0.5s ease-in-out forwards;
    }

    @keyframes fadeInAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .risk_ids {
        overflow-y: auto;
    }

    .risk_ids a {
        font-weight: inherit;
        text-decoration: none;
        padding: 3px;
        display: block;
        color: inherit;
    }

    .risk_ids a:hover {
        text-decoration-line: underline;
    }

    .counted_risks {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        text-decoration: none;
        font-weight: inherit;
        color: inherit;
    }
    
    /* Borderless btn */
    .btn-borderless {
        border: none;
        width: inherit;
        height: inherit;
        background: none;
        padding: 0;
        margin: 0;
        outline: none;
    }

    /* Make sure the text inside the button doesn't react to anything */
    .btn-borderless * {
        pointer-events: stroke;
            /* :style="'color:'+props.ButtonColor" */
        
    }
    .btn-color  {
        color: v-bind(ButtonColor);
    }

    /* Remove any potential hover or active effects on the button itself */
    .btn-borderless:hover, .btn-borderless:active, .btn-borderless:focus {
        background: none;
        text-decoration: none;
    }
</style>